import React from 'react';
import { Link } from 'react-router-dom';
import { theme } from '../services/helper.js';

function Navigation() {
  const userRole = localStorage.getItem("role");
  if(userRole > 1){
    return(
      <ul className={'navbar-nav sidebar sidebar-dark accordion bg-gradient-'+theme()} id="accordionSidebar">
        <Link className="sidebar-brand d-flex align-items-center justify-content-center" to="/">
        <div className="sidebar-brand-icon">
          <img src={global.config.asset_server +"/imgs/login.png"}/>
        </div>
        <div className="sidebar-brand-text mx-3">Keppa Travels</div>
        </Link>
        <hr className="sidebar-divider my-0"/>
        <li className="nav-item">
          <Link className="nav-link" to="/"><i className="fas fa-fw fa-tachometer-alt"></i>Dashboard</Link> 
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/packages"><i className="fas fa-fw fa-plane"></i>Packages</Link> 
        </li>
        <hr className="sidebar-divider d-none d-md-block"/>
        <li className="nav-item">
          <Link className="nav-link" to="/menu"><i className="fas fa-fw fa-sitemap"></i>Menu</Link> 
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/sections"><i className="fas fa-fw fa-clone"></i>Sections</Link> 
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/tags"><i className="fas fa-fw fa-tags"></i>Tags</Link> 
        </li>
        <li className="nav-item" style={{display: 'none'}}>
          <Link className="nav-link" to="/settings"><i className="fas fa-fw fa-cogs"></i>Settings</Link> 
        </li>
        <hr className="sidebar-divider d-none d-md-block"/>

        <div className="text-center d-none d-md-inline">
          <button className="rounded-circle border-0" id="sidebarToggle"></button>
        </div>
      </ul>
    )
  }else{
    return null;
  }
}

export default Navigation;