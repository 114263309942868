import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import '../config.js';

import { createFormData, sortObj } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';
import MenuListItem from '../components/menuListItem.js';

function MenuList() {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [label, setLabel] = useState('');
  const [parent, setParent] = useState(0);
  
  let createForm = useRef();
  
  const handleSubmit = (event) => {
    event.preventDefault();
    if(label){
      setLoading(true);
      axios.post(global.config.api_server+'menu',createFormData({
        label: label,
        parent: parent,
        task:"create",
        token: localStorage.getItem("token")
      })).then(res => {
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('New menu item created');
          setTimeout(function(){
            window.location.reload();
          }, 1000);
        }else{
          alertService.error(res.data.error);
        }
        setLoading(false);
      }).catch(err => {
        alertService.error(err);
        setLoading(false);
      });
    }else{
      alertService.error('Fill required fields');
      setLoading(false);
    }
  }
  
  useEffect(()=>{
    let uri = global.config.api_server+'menu?token='+localStorage.getItem("token");
    axios.get(uri)
    .then(res => {
      if(res.status === 200 && res.data.status === "success"){
        const temp = res.data.menu;
        setItems(temp)
        setLoading(false);
      }
    }).catch(err => {
      alertService.error(err);
    });
  }, []);

  if(loading){
    return <Progress/>;
  }else{
    return(
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <a href="#" className={'btn btn-icon-split float-right btn-primary'} data-toggle="modal" data-target="#createMenuModal" onClick={(e) => setParent(0)}>
                    <span className="icon text-white-50">
                      <i className="fas fa-sitemap"></i>
                    </span>
                    <span className="text">Create Main Menu Item</span>
                  </a>
                  <h6 className={'form-group mr-3 font-weight-bold text-primary'}>Menu</h6>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-12">
                      {items.map(item=>
                        <MenuListItem item={item} setParent={setParent} key={item.menu_id} level={1}/>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="createMenuModal" tabIndex="-1" role="dialog" aria-labelledby="create-popup" aria-hidden="true">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="create-plane">Create New Menu Item</h5>
                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <form ref={createForm}>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Label*</label>
                        <input type="text" value={label} maxLength="100" name="heading" className="form-control" id="heading" placeholder="Enter Heading" onChange={(e) => setLabel(e.target.value)}/>
                      </div>
                    </div>
                  </div>
                  {loading === true ? (
                    <Progress label='Creating..' small={true}/>
                  ):null}
                </div>
                <div className="modal-footer">
                  <button className="btn btn-default" type="reset" data-dismiss="modal">Close</button>
                  <button className={'btn btn-primary'} type="button" onClick={handleSubmit}>Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MenuList;