import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';

import { createFormData, theme } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import {Alert} from '../components/alert.js';

function Login(props) {
  const [otpGenerated, setOtpGenerated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState('');
  const [userId, setUserId] = useState('');
  const [passcode, setPasscode] = useState('');
  
  const handleOTPSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    axios.get(global.config.api_server+'login?user='+user).then(res => {
      setLoading(false);
      if(res.status === 200 && res.data.status === "success" && res.data.user){
        alertService.success("OTP send");
        setUserId(res.data.user)
        setOtpGenerated(true);
      }else if(res.data.error !== undefined){
        alertService.error(res.data.error);
      }
    }).catch(error => {
      alertService.error(error);
      setLoading(false);
    });
  }
  
  const handleLoginSubmit = (event) => {
    event.preventDefault();
    const loginInfo = createFormData({
      user: userId,
      passcode: passcode
    });
    setLoading(true);
    axios.post(global.config.api_server+'login',loginInfo).then(res => {
      setLoading(false);
      if(res.status === 200 && res.data.status === "success" && res.data.user){
        localStorage.setItem('token', res.data.user.token);
        localStorage.setItem('name', res.data.user.name);
        localStorage.setItem('email', res.data.user.email);
        localStorage.setItem('mobile', res.data.user.mobile);
        localStorage.setItem('role', res.data.user.role);
        localStorage.setItem('user_id', res.data.user.user_id);
        localStorage.setItem('supervisor', res.data.user.supervisor);
        localStorage.setItem('theme', res.data.user.theme);
        window.location.reload();
      }else if(res.data.error !== undefined){
        alertService.error(res.data.error);
      }
    }).catch(error => {
      alertService.error(error);
      setLoading(false);
    });
  }

  return(
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-10 col-lg-12 col-md-9">
          <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
              <div className="row">
                <div className="col-lg-6">
                  <img src={global.config.asset_server +"/imgs/logo.png"} className='d-none d-lg-block bg-login-image'/>
                </div>
                <div className="col-lg-6">
                  <div className="p-5">
                    <div className="text-center">
                      <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                    </div>
                    <Alert />
                    {otpGenerated === false ? (
                    <form className="otp" onSubmit={(e) => handleOTPSubmit(e)}>
                      <div className="form-group">
                        <input type="text" maxLength="50" name="user" value={user} className="form-control form-control-user" id="user" placeholder="Enter Email/Mobile" onChange={(e) => setUser(e.target.value)}/>
                      </div>
                      <input className={"btn btn-user btn-block btn-"+theme()} type="submit" value="Send OTP"/>
                    </form>
                    ):null}
                    {otpGenerated === true ? (
                    <form className="login" onSubmit={(e) => handleLoginSubmit(e)}>
                      <div className="form-group">
                        <input type="text" maxLength="50" name="passcode" value={passcode} className="form-control form-control-user" id="passcode" placeholder="Enter OTP" onChange={(e) => setPasscode(e.target.value)}/>
                      </div>
                      <input className={"btn btn-user btn-block btn-"+theme()} type="submit" value="Login"/>
                    </form>
                    ):null}
                    <div className="progress progress-sm mb-2 mt-3">
                    {loading === true ? (
                      <div className={'progress-bar progress-bar-striped progress-bar-animated bg-'+theme()} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                    ):null}
                    </div>                     
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login;