import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import '../config.js';

import { createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';
import RichTextEditor from '../components/rte.js';
import ColorSelector from './colorSelector.js';


function PackageAdditionalInfo(props){
  const [loading, setLoading] = useState(false);
  const [includes, setIncludes] = useState(props.item.includes);
  const [excludes, setExcludes] = useState(props.item.excludes);
  const [label, setLabel] = useState(props.item.label);
  const [label_color, setLabelColor] = useState(props.item.label_color);
  
  const handleSubmit = (event) => {
    event.preventDefault();
    if(includes && excludes){
      setLoading(true);
      axios.post(global.config.api_server+'package',createFormData({
        includes: includes,
        excludes: excludes,
        label: label,
        label_color: label_color,
        token: localStorage.getItem("token"),
        package_id: props.id,
        task: 'advance'
      })).then(res => {
        setLoading(false);
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('Package updated');
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
        setLoading(false);
      });
    }else{
      alertService.error('Some fields are required');
    }
  }

  return(
    <div>
      <div className="card shadow mb-4">
        <div className="card-header py-3">       
            <button onClick={(e) => handleSubmit(e)} className={'btn btn-icon-split float-right btn-primary'}>
              <span className="icon text-white-50">
                <i className="fas fa-save"></i>
              </span>
              <span className="text">Save</span>
            </button>
          <h6 className={'mt-2 font-weight-bold text-primary'}>Package - Additional Information</h6>
        </div>
        <div className="card-body">
          <div className="row">
            {loading === true ? (
              <Progress label='Saving' small={true}/>
            ):null}
            <div className="col-md-6">
              <div className="form-group">
                <label>Label</label>
                <input type="text" value={label} maxLength="50" name="label" className="form-control" id="label" placeholder="Enter Label" onChange={(e) => setLabel(e.target.value)}/>
              </div>
              <div className="form-group">
                <label>Includes</label>
                <RichTextEditor id={'package-includes-'+props.item.package_id} label={'Includes'} value={includes} handleSubmit={(e)=>setIncludes(e)}/>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Label Color</label>
                <ColorSelector id={'package-label-color-'+props.item.package_id} label={'Label Color'} value={label_color} handleSubmit={(e)=>setLabelColor(e)}/>
              </div>
              <div className="form-group">
                <label>Excludes</label>
                <RichTextEditor id={'package-excludes-'+props.item.package_id} label={'Excludes'} value={excludes} handleSubmit={(e)=>setExcludes(e)}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PackageAdditionalInfo;