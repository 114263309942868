import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import '../config.js';

import { createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';

function TagSelector(props) {
  const [loading, setLoading] = useState(false);
  const [searchTags, setSearchTags] = useState([]);
  const [tags, setTags] = useState(props.item.tags);
  
  const removeTag = (e, tag) => {
    e.preventDefault();
    if(tag && tag.tag && props.id){
      setLoading(true);
      axios.post(global.config.api_server+'tag_op',createFormData({
        tag: tag.tag,
        item: props.id,
        type: props.type,
        task: 'delete',
        token: localStorage.getItem("token")
      })).then(res => {
        setLoading(false);
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('Tag removed');
          const temp = [];
          tags.forEach(t => {
            if(t.tag != tag.tag){
              temp.push(t);
            }
          });
          setTags(temp);
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
        setLoading(false);
      });
    }else{
      alertService.error('Invalid Paramter');
    }
  }

  const addTag = (e, tag) => {
    e.preventDefault();
    console.log(tags.length);
    if(props.limit == 0 || (props.limit > 0 && props.limit > tags.length)){
      if(tag && tag.tag_id && props.id){
        setLoading(true);
        axios.post(global.config.api_server+'tag_op',createFormData({
          tag: tag.tag_id,
          item: props.id,
          type: props.type,
          task: 'add',
          token: localStorage.getItem("token")
        })).then(res => {
          setLoading(false);
          if(res.status === 200 && res.data.status === "success"){
            alertService.success('Tag added');
            tag.tag = tag.tag_id;
            tags.push(tag);
            setTags(tags);
            filterOutTags(searchTags);
          }else{
            alertService.error(res.data.error);
          }
        }).catch(err => {
          alertService.error(err);
          setLoading(false);
        });
      }else{
        alertService.error('Invalid Paramter');
      }
    }else{
      alertService.error('Only ' + props.limit + ' tag(s) allowed for this item.');
    }
  }

  const searchTag = (e) => {
    const value = e.target.value;
    if(value.length > 2){
      setLoading(true);
      axios.get(global.config.api_server+'tags?q='+value+'&token='+localStorage.getItem("token"))
      .then(res => {
        if(res.status === 200 && res.data.status === "success"){
          filterOutTags(res.data.tags);
        }
        setLoading(false);
      }).catch(err => {
        alertService.error(err);
        setLoading(false);
      });
    }
  }

  const saveTag = (e) => {
    if(e.key == 'Enter'){
      const label = e.target.value.trim();
      const identifier = label.toLowerCase().replaceAll(" ","-");
      axios.post(global.config.api_server+'tag',createFormData({
        label: label,
        identifier: identifier,
        task:"create",
        token: localStorage.getItem("token")
      })).then(res => {
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('New tag created');
          searchTag(e);
          addTag(e, res.data.tag);
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
      });
    }
  }

  const filterOutTags = (seachedTags) => {
    const temp = [];
    seachedTags.forEach(t => {
      const checkTag = tags.find(i => i.tag == t.tag_id);
      if(checkTag == undefined || checkTag == null){
        temp.push(t);
      }
    });
    setSearchTags(temp);
  }

  return(
    <div className="card shadow mb-4">
      <div className="card-header py-3">
        <h6 className={'mt-2 font-weight-bold text-primary'}>Select Tag(s)</h6>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>Selected Tags (Click to remove them)</label>
            </div>
            <div className="form-group">
              {tags.map(tag =>
                <span className={'mr-2'} key={tag.tag}>
                  <button className={'btn btn-icon-split btn-small btn-success'} onClick={(e) => removeTag(e, tag)}>
                    <span className="icon text-white-50">
                      <i className="fas fa-tag"></i>
                    </span>
                    <span className="text">{tag.label}</span>
                  </button>
                </span>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Search for tags (Write and press enter to add new tag)</label>
              <input type="text" maxLength="50" name="search" className="form-control" id="search" placeholder="Search" onChange={(e) => searchTag(e)} onKeyDown={(e) => saveTag(e)}/>
            </div>
            <div className="form-group">
            {searchTags.map(searchTag =>
              <span className={'mr-2'} key={searchTag.tag_id}>
                <button className={'btn btn-icon-split btn-small btn-info'} onClick={(e) => addTag(e, searchTag)}>
                  <span className="icon text-white-50">
                    <i className="fas fa-tag"></i>
                  </span>
                  <span className="text">{searchTag.label}</span>
                </button>
              </span>
            )} 
            </div>
          </div>
        </div>
        {loading === true ? (
          <Progress label='Loading..' small={true}/>
        ):null}
      </div>
    </div>
  )
}

export default TagSelector;