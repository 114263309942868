import React from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import '../config.js';
import { theme, createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';

function TagList(props) {
  let editButton = <Link to={"/tag/"+props.info.tag_id} className={'btn btn-icon-split btn-primary'}>
                      <span className="icon text-white-50">
                        <i className="fas fa-edit"></i>
                      </span>
                      <span className="text">Edit</span>
                    </Link>;
  return(
        <tr>
          <td>{props.info.tag_id}</td>
          <td>{props.info.identifier}</td>
          <td>{props.info.label}</td>
          <td>{editButton}</td>
        </tr>
  )
}

export default TagList;