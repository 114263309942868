import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import '../config.js';

import { createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';
import RichTextEditor from '../components/rte.js';
import TagSelector from './tagSelector.js';

function PackageBasicInfo(props){
  const [loading, setLoading] = useState(false);
  const [heading, setHeading] = useState(props.item.heading);
  const [identifier, setIdentifier] = useState(props.item.identifier);
  const [destination, setDestination] = useState(props.item.destination);
  const [duration, setDuration] = useState(props.item.duration);
  const [active, setActive] = useState(props.item.active);
  const [description, setDescription] = useState(props.item.description);
  
  const handleSubmit = (event) => {
    event.preventDefault();
    if(heading && identifier && destination && duration){
      setLoading(true);
      axios.post(global.config.api_server+'package',createFormData({
        heading: heading,
        identifier: identifier.toLowerCase().replaceAll(" ","-"),
        destination: destination,
        duration: duration,
        description: description,
        active: active,
        token: localStorage.getItem("token"),
        package_id: props.id,
        task: 'basic'
      })).then(res => {
        setLoading(false);
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('Package updated');
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
        setLoading(false);
      });
    }else{
      alertService.error('Some fields are required');
    }
  }

  const updateHeading = (value) => {
    setHeading(value);
    value = value.toLowerCase().replaceAll(" ","-");
    setIdentifier(value);
  }

  return(
    <div>
      <div className="card shadow mb-4">
        <div className="card-header py-3">       
          <button onClick={(e) => handleSubmit(e)} className={'btn btn-icon-split float-right btn-primary'}>
            <span className="icon text-white-50">
              <i className="fas fa-save"></i>
            </span>
            <span className="text">Save</span>
          </button>
          <h6 className={'mt-2 font-weight-bold text-primary'}>Package - Basic Information</h6>
        </div>
        <div className="card-body">
          <div className="row">
            {loading === true ? (
              <Progress label='Saving' small={true}/>
            ):null}
            <div className="col-md-6">
              <div className="form-group">
                <label>Heading *</label>
                <input type="text" value={heading} maxLength="100" name="heading" className="form-control" id="heading" placeholder="Enter Heading" onChange={(e) => updateHeading(e.target.value)}/>
              </div>
              <div className="form-group">
                <label>Identifier * <small>( Do not use space )</small></label>
                <input type="text" value={identifier} maxLength="100" name="identifier" className="form-control" id="identifier" placeholder="Enter Identifier" onChange={(e) => setIdentifier(e.target.value)}/>
              </div>
              <div className="form-group">
                <label>Duration *</label>
                <input type="text" value={duration} maxLength="50" name="duration" className="form-control" id="duration" placeholder="Enter Duration" onChange={(e) => setDuration(e.target.value)}/>
              </div>
              <div className="form-group">
                <label>Destination *</label>
                <textarea value={destination} maxLength="200" name="destination" className="form-control" id="destination" placeholder="Enter Destination" onChange={(e) => setDestination(e.target.value)}></textarea>
              </div>
              <div className="form-group">
                <label>Status</label>
                <select name="active" className="form-control" id="active" value={active} onChange={(e) => setActive(e.target.value)}>
                  <option value="1">Active</option>
                  <option value="0">In-Active</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Description</label>
                <RichTextEditor id={'package-description-'+props.item.package_id} label={'Description'} value={description} handleSubmit={(e)=>setDescription(e)}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TagSelector item={props.item} id={props.item.package_id} type="PACKAGE" limit={0}/>
    </div>
  )
}

export default PackageBasicInfo;