import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import '../config.js';

import { createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';
import RichTextEditor from '../components/rte.js';

function TagInfo(props) {
  const [loading, setLoading] = useState(false);
  const [label, setLabel] = useState(props.tag.label);
  const [identifier, setIdentifier] = useState(props.tag.identifier);
  const [description, setDescription] = useState(props.tag.description);

  const updateLabel = (value) => {
    setLabel(value);
    value = value.toLowerCase().replaceAll(" ","-");
    setIdentifier(value);
  }
  
  const handleSubmit = (event) => {
    event.preventDefault();
    if(label && props.id){
      setLoading(true);
      axios.post(global.config.api_server+'tag',createFormData({
        label: label,
        id: props.id,
        description: description,
        identifier: identifier.toLowerCase().replaceAll(" ","-"),
        task: 'edit',
        token: localStorage.getItem("token")
      })).then(res => {
        setLoading(false);
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('Tag updated');
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
        setLoading(false);
      });
    }else{
      alertService.error('Some fields are required');
    }
  }
  return(
    <div className="card shadow mb-4">
      <div className="card-header py-3">            
        <button onClick={handleSubmit} className={'btn btn-icon-split float-right btn-primary'}>
          <span className="icon text-white-50">
            <i className="fas fa-save"></i>
          </span>
          <span className="text">Save</span>
        </button>
        <h6 className={'mt-2 font-weight-bold text-primary'}>Tag Information</h6>
      </div>
      <div className="card-body">
        <div className="row justify-content-center">
          {loading === true ? (
            <Progress label='Saving' small={true}/>
          ):null}
          <div className="col-md-6">
            <div className="form-group">
              <label>Label *</label>
              <input type="text" value={label} maxLength="50" name="label" className="form-control" id="label" placeholder="Enter Label" onChange={(e) => updateLabel(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>Identifier *<small>( Do not use space )</small></label>
              <input type="text" value={identifier} maxLength="50" name="identifier" className="form-control disabled" id="name" placeholder="Enter Identfier" onChange={(e) => setIdentifier(e.target.value)}/>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Description</label>
              <RichTextEditor id={'package-description-'+props.tag.tag_id} label={'Description'} value={description} handleSubmit={(e)=>setDescription(e)}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TagInfo;