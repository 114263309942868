import React from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import '../config.js';
import { theme, createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';

function SectionListItem(props) {
  return(
    <div>
      <span>
        <a href="#" className={'btn btn-icon-split float-right btn-primary'} data-toggle="modal" data-target="#createTabModal" onClick={(e) => props.setParent(props.item.section_id)}>
          <span className="icon text-white-50">
            <i className="fas fa-plus"></i>
          </span>
        </a>
        <Link to={"/section/"+props.item.section_id} className={'btn btn-icon-split mr-2 float-right btn-primary'}>
          <span className="icon text-white-50">
            <i className="fas fa-edit"></i>
          </span>
        </Link>
      </span>
      <span><strong>{props.item.heading}</strong></span>
      {props.item.child && props.item.child.length > 0 && 
      <div className="m-4">
        {props.item.child.map(item=>
          <div className="p2">
            <hr/>
            <Link to={"/tab/"+item.tab_id} className={'btn btn-icon-split mr-2 float-right btn-primary'}>
              <span className="icon text-white-50">
                <i className="fas fa-edit"></i>
              </span>
            </Link>
            {item.heading}
          </div>
        )}
      </div>
      }
      <hr/>
    </div>
  )
}

export default SectionListItem;