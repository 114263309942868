import React, {useState, useEffect, useRef} from 'react';
import { useParams } from 'react-router';
import {Link, useNavigate} from 'react-router-dom';
import axios from 'axios';
import '../config.js';

import { alertService } from '../services/alert.service.js';
import PackageBasicInfo from '../components/packageBasicInfo.js';
import ImagePicker from '../components/imagePicker.js';
import PackageAdditionalInfo from '../components/packageAdditionalInfo.js'
import PackageItineraryInfo from '../components/packageItineraryInfo.js'
import Progress from '../components/progress.js';

function Package(props) {
  const { id, subnav } = useParams();
  const [item, setItem] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(()=>{
    axios.get(global.config.api_server+'package?id='+id+'&token='+localStorage.getItem("token")) 
    .then(res => {
      if(res.status === 200 && res.data.status === "success"){
        setItem(res.data.package);
        setLoading(false);
      }else{
        alertService.error(res.data.error);
      }
    }).catch(err => {
      alertService.error(err);
      navigate("/packages");
    });
  }, []);
  
  const handleDelete = (event) => {
    event.preventDefault();
    if(id){
      axios.get(global.config.api_server+'client?id='+id+'&token='+localStorage.getItem("token")).then(res => {
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('Package deleted');
          navigate("/packages");
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
      });
    }else{
      alertService.error('Invalid Input');
    }
  }
  
  const checkSubNav = (param) => {
    let design = "";
    if(subnav === param){
      design = " disabled";
    }else if(param === 'basic' && (subnav === null || subnav === undefined)){
      design = " disabled";
    }
    return design;
  }

  let subnavItem = <PackageBasicInfo id={id} item={item}></PackageBasicInfo>;
  if(subnav === 'images'){
    subnavItem = <ImagePicker id={id} item={item} type="PACKAGE" limit={0}></ImagePicker>;
  }else if(subnav === 'additional'){
    subnavItem = <PackageAdditionalInfo id={id} item={item}></PackageAdditionalInfo>;
  }else if(subnav === 'itinerary'){
    subnavItem = <PackageItineraryInfo id={id} item={item}></PackageItineraryInfo>;
  }
  if(loading){
    return <Progress/>;
  }else{
    return(
      <div className="container-fluid">
        <div className="mb-3">
          <button className={'btn btn-icon-split float-right btn-danger'} data-toggle="modal" data-target="#deletePackageModal">
            <span className="icon text-white-50">
              <i className="fas fa-trash"></i>
            </span>
            <span className="text">Delete</span>
          </button>
          <Link to={"/packages"} className="btn btn-light btn-icon-split mr-2">
            <span className="icon text-white-50">
              <i className="fas fa-arrow-left"></i>
            </span>
            <span className="text">Back</span>
          </Link>
          <Link to={"/package/"+id} className={'btn btn-icon-split mr-2 btn-primary' + checkSubNav('basic')}>
            <span className="icon text-white-50">
              <i className="fas fa-train"></i>
            </span>
            <span className="text">Basic Info</span>
          </Link>
          <Link to={"/package/"+id+"/additional"} className={'btn btn-icon-split mr-2 btn-primary' + checkSubNav('additional')}>
            <span className="icon text-white-50">
              <i className="fas fa-bus"></i>
            </span>
            <span className="text">Additional Info</span>
          </Link>
          <Link to={"/package/"+id+"/itinerary"} className={'btn btn-icon-split mr-2 btn-primary' + checkSubNav('itinerary')}>
            <span className="icon text-white-50">
              <i className="fas fa-map"></i>
            </span>
            <span className="text">Itinerary Info</span>
          </Link>
          <Link to={"/package/"+id+"/images"} className={'btn btn-icon-split mr-2 btn-primary' + checkSubNav('images')}>
            <span className="icon text-white-50">
              <i className="fas fa-image"></i>
            </span>
            <span className="text">Images</span>
          </Link>
        </div>
        
        {subnavItem}
        
        <div className="modal fade" id="deletePackageModal" tabIndex="-1" role="dialog" aria-labelledby="delete-popup" aria-hidden="true">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="delete-client-popup">Confirm deletion?</h5>
                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">Are you sure, you want to delete this package?</div>
              <div className="modal-footer">
                <button className="btn btn-default" type="button" data-dismiss="modal">Cancel</button>
                <button className={'btn ml-3 btn-danger'} onClick={handleDelete} data-dismiss="modal">Delete</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Package;