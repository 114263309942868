import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import '../config.js';

import { createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import ItineraryListItem from '../components/itineraryListItem.js';
import Progress from '../components/progress.js';

function PackageItineraryInfo(props){
  const [loading, setLoading] = useState(false);
  const [heading, setHeading] = useState();
  const [day, setDay] = useState(1);
  const [items, setItems] = useState(props.item.itineraries);
  
  const handleSubmit = (event) => {
    event.preventDefault();
    if(heading && day){
      setLoading(true);
      axios.post(global.config.api_server+'package',createFormData({
        heading: heading,
        day: day,
        token: localStorage.getItem("token"),
        package_id: props.id,
        task: 'create_itinerary'
      })).then(res => {
        setLoading(false);
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('Itinerary created');
          setTimeout(function(){
            window.location.reload();
          }, 1000);
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
        setLoading(false);
      });
    }else{
      alertService.error('Some fields are required');
    }
  }

  return(
    <div>
      <div className="card shadow mb-4">
        <div className="card-header py-3">       
            <button className={'btn btn-icon-split float-right btn-primary'} data-toggle="modal" data-target="#createItineraryModal">
              <span className="icon text-white-50">
                <i className="fas fa-map"></i>
              </span>
              <span className="text">Create New Itinerary</span>
            </button>
          <h6 className={'mt-2 font-weight-bold text-primary'}>Package - Itinerary Information</h6>
        </div>
      </div>
      {loading === true ? (
        <Progress label='Creating'/>
      ):null}
      {items.map(i =>
        <ItineraryListItem item={i} key={i.itinerary_id}/>
      )}
      <div className="modal fade" id="createItineraryModal" tabIndex="-1" role="dialog" aria-labelledby="update-invoice-popup" aria-hidden="true">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="update-invoice-popup">Create New Itinerary</h5>
                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label>Heading</label>
                  <input type="text" maxLength="80" name="heading" value={heading} className="form-control" id="heading" placeholder="Enter Heading" onChange={(e) => setHeading(e.target.value)}/>
                </div>
                <div className="form-group">
                  <label>Day</label>
                  <input type="number" maxLength="80" name="day" value={day} className="form-control" id="day" placeholder="Enter Day" onChange={(e) => setDay(e.target.value)}/>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-default" type="button" data-dismiss="modal">Cancel</button>
                <button className={'btn ml-3 btn-primary'} onClick={(e) => handleSubmit(e)}  data-dismiss="modal">Create</button>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default PackageItineraryInfo;