import React, {useState, useEffect, useRef} from 'react';
import '../config.js';

import ColorPicker from 'react-pick-color';

function ColorSelector(props) {
  const [value, setValue] = useState(props.value);

  const onClick = () =>{
    props.handleSubmit(value);
  }

  const handleUpdate = (v) => {
    setValue(v);
    props.handleSubmit(v);
  };

  return (
    <div>
      <a href="#" className={'btn btn-icon-split btn-dark'} style={{backgroundColor: value}} data-toggle="modal" data-target={'#'+props.id + '-rte-modal'}>
        <span className="icon text-white-50">
          <i className="fas fa-paint-brush"></i>
        </span>
        <span className="text">{value}</span>
      </a>
      <div className="modal fade" id={props.id + '-rte-modal'} tabIndex="-1" role="dialog" aria-labelledby="rte-popup" aria-hidden="true">
        <div className="modal-dialog modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="create-popup">Color Picker</h5>
              <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
              <div className="modal-body color-selector">
                <div className="form-group">
                  <label>{props.label}</label>
                  <ColorPicker value={value} name="value" id="value" onChange={(e) => handleUpdate(e.hex)}/>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-default" type="reset" data-dismiss="modal">Close</button>
                <button className={'btn btn-primary'} type="button" data-dismiss="modal" onClick={onClick}>Select</button>
              </div>
          </div>
        </div>
      </div>
    </div>
    )
}

export default ColorSelector;