import React, {useState} from 'react';
import axios from 'axios';
import '../config.js';
import { createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';
import ColorSelector from './colorSelector.js';

function SectionDetail(props) {
  const [heading, setHeading] = useState(props.item.heading);
  const [ranking, setRanking] = useState(props.item.ranking);
  const [color, setColor] = useState(props.item.color);
  const [loading, setLoading] = useState(false);
  

  const handleSubmit = (event) => {
    event.preventDefault();
    if(heading && ranking){
      setLoading(true);
      axios.post(global.config.api_server+'section',createFormData({
        heading: heading,
        ranking: ranking,
        color: color,
        token: localStorage.getItem("token"),
        section_id: props.item.section_id,
        task: 'update'
      })).then(res => {
        setLoading(false);
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('Section updated');
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
        setLoading(false);
      });
    }else{
      alertService.error('Some fields are required');
    }
  }

  return(
    <div>
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <button onClick={(e) => handleSubmit(e)} className={'btn btn-icon-split float-right btn-primary'}>
            <span className="icon text-white-50">
              <i className="fas fa-save"></i>
            </span>
            <span className="text">Save</span>
          </button>
          <h6 className={'form-group mr-3 font-weight-bold text-primary'}>Section Item - {props.item.heading}</h6>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-sm-12">
              <div className="row justify-content-center">
                {loading === true ? (
                  <Progress label='Saving' small={true}/>
                ):null}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Heading *</label>
                    <input type="text" value={heading} maxLength="100" name="heading" className="form-control" id="heading" placeholder="Enter Heading" onChange={(e) => setHeading(e.target.value)}/>
                  </div>
                  <div className="form-group">
                    <label>Ranking (Highest ranking will show on top) *</label>
                    <input type="number" value={ranking} maxLength="100" name="ranking" className="form-control" id="ranking" placeholder="Enter Ranking" onChange={(e) => setRanking(e.target.value)}/>
                  </div>
                  <div className="form-group">
                    <label>Color</label>
                    <ColorSelector id={'section-color-'+props.item.menu_id} label={'Color'} value={color} handleSubmit={(e)=>setColor(e)}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionDetail;