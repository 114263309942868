import React, {useState, useEffect, useRef} from 'react';
import { useParams } from 'react-router';
import axios from 'axios';
import '../config.js';
import {Link, useNavigate } from 'react-router-dom';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';
import MenuDetail from '../components/menuDetail.js';

function Menu() {
  const [loading, setLoading] = useState(true);
  const [menuItem, setMenuItem] = useState();
  const { id, subnav } = useParams();
  const navigate = useNavigate();

  
  useEffect(()=>{
    let uri = global.config.api_server+'menu?id='+id+'&token='+localStorage.getItem("token");
    axios.get(uri)
    .then(res => {
      if(res.status === 200 && res.data.status === "success"){
        const temp = res.data.menu;
        setMenuItem(temp);
        setLoading(false);
      }
    }).catch(err => {
      alertService.error(err);
    });
  }, []);

  const handleDelete = (event) => {
    event.preventDefault();
    if(id){
      axios.get(global.config.api_server+'menu?id='+id+'&token='+localStorage.getItem("token")+'&task=delete').then(res => {
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('Tag deleted');
          navigate("/menu");
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
      });
    }else{
      alertService.error('Tag ID not found');
    }
  }

  if(loading){
    return <Progress/>;
  }else{
    return(
      <div>
        <div className="container-fluid">
          <div className="mb-3">
            <button  className={'btn btn-icon-split float-right btn-danger ml-2'} data-toggle="modal" data-target="#deleteMenuModal">
              <span className="icon text-white-50">
                <i className="fas fa-trash"></i>
              </span>
              <span className="text">Delete</span>
            </button>
            <Link to={"/menu"} className="btn btn-light btn-icon-split mr-2">
              <span className="icon text-white-50">
                <i className="fas fa-arrow-left"></i>
              </span>
              <span className="text">Back</span>
            </Link>
          </div>
          <div className="row">
            <div className="col-md-12">
              <MenuDetail item={menuItem}/>
            </div>
          </div>
        </div>
        <div className="modal fade" id="deleteMenuModal" tabIndex="-1" role="dialog" aria-labelledby="delete-popup" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="delete-popup">Confirm deletion?</h5>
                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">Are you sure, you want to delete this tag?</div>
              <div className="modal-footer">
                <button className="btn btn-default" type="button" data-dismiss="modal">Cancel</button>
                <button className={'btn ml-3 btn-danger'} onClick={(e) => handleDelete(e)} data-dismiss="modal">Delete</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Menu;