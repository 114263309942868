import React, {useEffect} from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import axios from 'axios';
import Dashboard from './dashboard.js';
import Packages from './packages.js';
import Package from './package.js';
import MenuList from './menuList.js';
import Menu from './menu.js';
import Tags from './tags.js';
import Tag from './tag.js';
import Settings from './settings.js';
import Topnav from '../components/topnav.js';
import Footer from '../components/footer.js';
import Sections from './sections.js';
import Section from './section.js';
import Tab from './tab.js';
import Report from './report.js';

import { alertService } from '../services/alert.service.js';
import {Alert} from '../components/alert.js';

import Navigation from '../components/navigation.js';


function Base(props) {
  
  useEffect(()=>{
    axios.get(global.config.api_server+'login?token='+localStorage.getItem("token"))
    .then(res => {
      let status = false;
      if(res.status == 200 && res.data.status === "success" && res.data.user){
        localStorage.setItem('name', res.data.user.name);
        localStorage.setItem('email', res.data.user.email);
        localStorage.setItem('mobile', res.data.user.mobile);
        localStorage.setItem('role', res.data.user.role);
        localStorage.setItem('user_id', res.data.user.user_id);
        localStorage.setItem('supervisor', res.data.user.supervisor);
        status = true;
      }else if(res.data.error !== undefined){
        alertService.error(res.data.error);
      }else{
        alertService.error("Unknown Error");
      }
      if(!status){
        localStorage.clear();
        setTimeout(
          () => window.location.reload(), 
          2000
        );
      }
    }).catch(error => {
      alertService.error("Network error");
      localStorage.clear();
      window.location.reload();
    });
  }, []);
  
  return(
      <BrowserRouter>
        <Navigation/>
        <Alert />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topnav/>
              <Routes>
                <Route path="*" element={<Dashboard />} />
                <Route index path='/' element={ <Dashboard /> } ></Route>
                <Route exact path='/packages' element={ <Packages /> } ></Route>
                <Route exact path='/package/:id' element={ <Package /> } ></Route>
                <Route exact path='/package/:id/:subnav' element={ <Package /> } ></Route>
                <Route exact path='/tags' element={ <Tags /> } ></Route>
                <Route exact path='/tag/:id' element={ <Tag /> } ></Route>
                <Route exact path='/menu' element={ <MenuList /> } ></Route>
                <Route exact path='/menu/:id' element={ <Menu /> } ></Route>
                <Route exact path='/sections' element={ <Sections /> } ></Route>
                <Route exact path='/section/:id' element={ <Section /> } ></Route>
                <Route exact path='/tab/:id' element={ <Tab /> } ></Route>
                <Route exact path='/settings' element={ <Settings /> } ></Route>
                <Route exact path='/report/:subnav' element={ <Report /> } ></Route>
              </Routes>
          </div>
          <Footer/>
        </div>
      </BrowserRouter>
  );
}

export default Base;