import React from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import '../config.js';
import { theme, createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';

function MenuListItem(props) {
  return(
    <div className="p-2">
      <span>
        {((props.level < 3 && props.item.style == 'CATEGORY_LIST') || (props.level < 2 && props.item.style == 'THUMBNAIL')) &&
          <a href="#" className={'btn btn-icon-split float-right btn-primary'} data-toggle="modal" data-target="#createMenuModal" onClick={(e) => props.setParent(props.item.menu_id)}>
            <span className="icon text-white-50">
              <i className="fas fa-plus"></i>
            </span>
          </a>
        }
        <Link to={"/menu/"+props.item.menu_id} className={'btn btn-icon-split mr-2 float-right btn-primary'}>
          <span className="icon text-white-50">
            <i className="fas fa-edit"></i>
          </span>
        </Link>
      </span>
      <span>{props.item.label}</span>
      <hr/>
      <div className="ml-5">
        {props.item.child.map(item=>
          <MenuListItem item={item} setParent={props.setParent} key={item.menu_id} level={props.level+1}/>
        )}
      </div>
    </div>
  )
}

export default MenuListItem;