import React, {useState, useEffect, useRef} from 'react';

import { theme } from '../services/helper.js';
import { alertService, alertType } from '../services/alert.service.js';

const defaultId = 'default-alert';

function Alert(props){
  const [alerts, setAlerts] = useState([]);
  let subscription;

  useEffect(() => {
    subscription = alertService.onAlert(props.id).subscribe(alert => {   
      setAlerts([...alerts, alert]);
      setTimeout(() => removeAlert(alert), 3000);
    });
    return () => {
      subscription.unsubscribe();
    }
  }, [])
  
  const removeAlert = (alert) => {
      const alertWithFade = {alert, fade: true };
      setAlerts(alerts.map(x => x === alert ? alertWithFade : x));
      setTimeout(() => {
          setAlerts(alerts.filter(x => x !== alertWithFade));
      }, 250);
  }
  
  const cssClasses = (alert) => {
    if (!alert) return;
    const classes = ['global-alert','alert', 'alert-dismissable'];
    const alertTypeClass = {
        [alertType.success]: 'alert alert-success',
        [alertType.error]: 'alert alert-danger',
        [alertType.info]: 'alert alert-info',
        [alertType.warning]: 'alert alert-warning',
        [alertType.secondary]: 'alert alert-secondary',
        [alertType.dark]: 'alert alert-dark',
        [alertType.primary]: 'alert alert-primary'
    }
    classes.push(alertTypeClass[alert.type]);
    if (alert.fade) {
        classes.push('fade');
    }
    return classes.join(' ');
  }
  
  const iconClass = (alert) => {
    if (!alert) return;
    let icon;
    if(alert.type == [alertType.success]){
      icon = "fas mr-2 fa-check-circle";
    }else if(alert.type == [alertType.error]){
      icon = "fas mr-2 fa-exclamation-triangle";
    }else if(alert.type == [alertType.info]){
      icon = "fas mr-2 fa-info-circle";
    }else if(alert.type == [alertType.warning]){
      icon = "fas mr-2 fa-exclamation";
    }
    return icon;
  }

  if (!alerts.length){
    return null;
  }else{
    return (
        <div>
            {alerts.map((alert, index) =>
                <div key={index} className={cssClasses(alert)}>
                    <a className="close" onClick={() => removeAlert(alert)}>&times;</a>
                    <i className={iconClass(alert)}></i> <span dangerouslySetInnerHTML={{__html: alert.message}}></span>
                </div>
            )}
        </div>
    )
  }
}

Alert.defaultId = defaultId;
export {Alert};