import React from 'react';
import {Link} from 'react-router-dom';
import '../config.js';

import { theme } from '../services/helper.js';

function PackageListItem(props) {
  let status = "In-Active";
  let statusClass = "table-danger";
  if(props.info.active != "0"){
    status = "Active";
    statusClass = "";
  }
  
  return(
        <tr className={statusClass}>
          <td>{props.info.heading}</td>
          <td>{props.info.identifier}</td>
          <td>{props.info.duration}</td>
          <td>{props.info.destination}</td>
          <td>{props.info.label}</td>
          <td>{status}</td>
          <td>
            <Link to={"/package/"+props.info.package_id} className={'mr-2 btn btn-icon-split btn-'+theme()} data-toggle="tooltip" data-placement="bottom" title="Info">
              <span className="icon text-white-50">
                <i className="fas fa-edit"></i>
              </span>
            </Link>
          </td>
        </tr>
  )
}

export default PackageListItem;