import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import '../config.js';
import { theme, createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';
import RichTextEditor from '../components/rte.js';

function ItineraryListItem(props) {
  const [loading, setLoading] = useState(false);
  const [heading, setHeading] = useState(props.item.heading);
  const [day, setDay] = useState(props.item.day);
  const [description, setDescription] = useState(props.item.description);

  const handleDelete = (event) => {
    event.preventDefault();
    if(props.item.itinerary_id){
      axios.get(global.config.api_server+'package?id='+props.item.itinerary_id+"&task=delete_itinerary&token="+localStorage.getItem("token"))
      .then(res => {
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('Itinerary Deleted');
          setTimeout(function(){
            window.location.reload();
          }, 1000);
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
      });
    }else{
      alertService.error('Error deleting itinerary');
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if(heading && props.item.itinerary_id && day){
      axios.post(global.config.api_server+'package',createFormData({
        heading: heading,
        day: day,
        description: description,
        id: props.item.itinerary_id,
        task:"update_itinerary",
        token: localStorage.getItem("token")
      })).then(res => {
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('Itinerary Updated');
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
      });
    }else{
      alertService.error('Error deleting image');
    }
  }


  return(
    <div className="card shadow mb-4">
      <div className="card-header py-3">
          <button onClick={(e) => handleDelete(e)} className={'btn btn-icon-split float-right ml-2 btn-danger'}>
            <span className="icon text-white-50">
              <i className="fas fa-trash"></i>
            </span>
            <span className="text">Delete</span>
          </button>    
          <button onClick={(e) => handleSubmit(e)} className={'btn btn-icon-split float-right btn-primary'}>
            <span className="icon text-white-50">
              <i className="fas fa-save"></i>
            </span>
            <span className="text">Save</span>
          </button>
        <h6 className={'mt-2 font-weight-bold text-primary'}>Itinerary | Day {day} | {heading}</h6>
      </div>
      <div className="card-body">
        <div className="row">
          {loading === true ? (
            <Progress label='Saving' small={true}/>
          ):null}
          <div className="col-md-6">
            <div className="form-group">
              <label>Heading</label>
              <input type="text" value={heading} maxLength="50" name="heading" className="form-control" id="heading" placeholder="Enter Heading" onChange={(e) => setHeading(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>Day</label>
              <input type="number" value={day} maxLength="50" name="day" className="form-control" id="day" placeholder="Enter Day" onChange={(e) => setDay(e.target.value)}/>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Description</label>
              <RichTextEditor id={'package-description-'+props.item.itinerary_id} label={'Description'} value={description} handleSubmit={(e)=>setDescription(e)}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ItineraryListItem;