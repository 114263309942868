import React, {useState, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';

function Dasboard() {
    const [dashboard, setDashboard] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        let uri = global.config.api_server+'dashboard?token='+localStorage.getItem("token");
        axios.get(uri)
        .then(res => {
            if(res.status === 200 && res.data.status === "success"){
            const temp = res.data.dashboard;
            setDashboard(temp)
            setLoading(false);
            }
        }).catch(err => {
            alertService.error(err);
        });
    }, []);

    if(loading){
        return <Progress/>;
    }else{
        return(
            <div className="container-fluid">
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
                </div>
                <div className="row">
                    <div className="col-xl-6 col-md-6 mb-4">
                        <Link to={"/packages"}>
                            <div className={"card shadow h-100 py-2 border-left-primary"}>
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className={"text-xs font-weight-bold text-uppercase mb-1 text-primary"}>
                                                Packages (Active)</div>
                                            <div className="h5 mb-0 font-weight-bold text-gray-800">{dashboard.packages.count}</div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="fas fa-plane fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-xl-6 col-md-6 mb-4">
                        <Link to={"/tags"}>
                            <div className={"card shadow h-100 py-2 border-left-primary"}>
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className={"text-xs font-weight-bold text-uppercase mb-1 text-primary"}>
                                                Tags</div>
                                            <div className="h5 mb-0 font-weight-bold text-gray-800">{dashboard.tags.count}</div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="fas fa-tags fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default Dasboard;