import React, {useState} from 'react';
import axios from 'axios';
import '../config.js';
import { createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';
import TagSelector from './tagSelector.js';
import ImagePicker from '../components/imagePicker.js';
import ColorSelector from './colorSelector.js';

function MenuDetail(props) {
  const [label, setLabel] = useState(props.item.label);
  const [style, setStyle] = useState(props.item.style);
  const [ranking, setRanking] = useState(props.item.ranking);
  const [showcase, setShowcase] = useState(props.item.showcase);
  const [color, setColor] = useState(props.item.color);
  const [loading, setLoading] = useState(false);
  

  const handleSubmit = (event) => {
    event.preventDefault();
    if(label && style && ranking){
      setLoading(true);
      axios.post(global.config.api_server+'menu',createFormData({
        label: label,
        style: style,
        ranking: ranking,
        showcase: showcase,
        color: color,
        token: localStorage.getItem("token"),
        menu_id: props.item.menu_id,
        task: 'update'
      })).then(res => {
        setLoading(false);
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('Menu updated');
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
        setLoading(false);
      });
    }else{
      alertService.error('Some fields are required');
    }
  }

  return(
    <div>
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <button onClick={(e) => handleSubmit(e)} className={'btn btn-icon-split float-right btn-primary'}>
            <span className="icon text-white-50">
              <i className="fas fa-save"></i>
            </span>
            <span className="text">Save</span>
          </button>
          <h6 className={'form-group mr-3 font-weight-bold text-primary'}>Menu Item - {props.item.label}</h6>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-sm-12">
              <div className="row">
                {loading === true ? (
                  <Progress label='Saving' small={true}/>
                ):null}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Label *</label>
                    <input type="text" value={label} maxLength="100" name="label" className="form-control" id="label" placeholder="Enter Label" onChange={(e) => setLabel(e.target.value)}/>
                  </div>
                  <div className="form-group">
                    <label>Showcase - Colored Carousel</label>
                    <select name="showcase" className="form-control" id="showcase" value={showcase} onChange={(e) => setShowcase(e.target.value)}>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                  </div>
                  {props.item.parent === 0 || props.item.parent === '0' ? (
                    <div className="form-group">
                      <label>Style</label>
                      <select name="style" className="form-control" id="style" value={style} onChange={(e) => setStyle(e.target.value)}>
                        <option value="CATEGORY_LIST">Category List</option>
                        <option value="THUMBNAIL">Thumbnail</option>
                      </select>
                    </div>
                  ):null}
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Ranking (Highest ranking will show on top) *</label>
                    <input type="number" value={ranking} maxLength="100" name="ranking" className="form-control" id="ranking" placeholder="Enter Ranking" onChange={(e) => setRanking(e.target.value)}/>
                  </div>
                  <div className="form-group">
                    <label>Showcase Color</label>
                    <ColorSelector id={'menu-showcase-color-'+props.item.menu_id} label={'Showcase Color'} value={color} handleSubmit={(e)=>setColor(e)}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TagSelector item={props.item} id={props.item.menu_id} type="MENU" limit={1}/>
      <ImagePicker id={props.item.menu_id} item={props.item} type="MENU" limit={1}></ImagePicker>
    </div>
  )
}

export default MenuDetail;