import React, {useState, useEffect, useRef} from 'react';
import JoditEditor from "jodit-react";


function RichTextEditor(props){
  const [value, setValue] = useState(decodeURI(props.value));
  const editor = useRef(null);

  const onClick = () =>{
    props.handleSubmit(value);
  }

  const handleUpdate = (v) => {
    setValue(v);
    props.handleSubmit(v);
  };

  const buttons = [
    'bold',
    'strikethrough',
    'underline',
    'italic',
    '|',
    'superscript',
    'subscript',
    '|',
    'ul',
    'ol',
    '|',
    'paragraph',
    'brush',
    '|',
    'hr',
    'copyformat',
    '|',
    'fullsize',
    'selectall',
    '|',
    'source',
    '|'
  ];

  const config ={
    readonly: false,
    limitChars: 8000,
    height: 400,
    toolbar: true,
    toolbarButtonSize: "large",
    toolbarAdaptive: false,
    showCharsCounter: true,
	  showWordsCounter: true,
    buttons: buttons
  }

  return (
    <div>
      <a href="#" className={'btn btn-icon-split btn-primary'} data-toggle="modal" data-target={'#'+props.id + '-rte-modal'}>
        <span className="icon text-white-50">
          <i className="fas fa-edit"></i>
        </span>
        <span className="text">{props.label}</span>
      </a>
      <div className="modal fade" id={props.id + '-rte-modal'} tabIndex="-1" role="dialog" aria-labelledby="rte-popup" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="create-popup">Rich Text Editor</h5>
              <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <form>
              <div className="modal-body">
                <div className="form-group">
                  <label>{props.label}</label>
                  <JoditEditor
                    ref={editor}
                    value={value}
                    config={config}
                    onBlur={handleUpdate}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-default" type="reset" data-dismiss="modal">Close</button>
                <button className={'btn btn-primary'} type="button" data-dismiss="modal" onClick={onClick}>Done Editing</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="mt-2 border rounded-lg p-2" dangerouslySetInnerHTML={{ __html: value }} />
    </div>
    )
}

export default RichTextEditor;